'use strict';

angular.module('windmanagerApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('cart', {
        url: '/cart',
        template: '<cart></cart>',
        authenticate: true,
        data: {
          bodyClass: ''
        }
      });
  });
